@tailwind base;
@tailwind components;
@tailwind utilities;
@media (prefers-color-scheme: dark) {
  body {
    background-color: #ffffff;
  }
}
:root {
  color-scheme: light only;
}
.border-gradient {
  border: 2px solid transparent; /*dont touch it */
  border-radius: 9999px; 
  background-image: linear-gradient(#EBF0FF, #EBF0FF), linear-gradient(to right, #4FD5FF, #FC5BFF); 
  background-origin: border-box; 
  background-clip: content-box, border-box; 
}
